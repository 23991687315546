<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onOk" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>选择商品</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品.商品编号')" prop="goodsCode">
                <a-input v-model="queryParam.goodsCode" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品.商品编号')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品.商品名称')" prop="goodsName">
                <a-input v-model="queryParam.goodsName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品.商品名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商品.商品分类')" prop="categoryId">
                <a-tree-select v-model="queryParam.categoryId" style="width: 100%"
                               :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="cateOptions" :placeholder="$t('通用.输入.请选择')+$t('商品.商品分类')"
                               :replaceFields="{ children: 'children', title: 'categoryName', key: 'id', value: 'id' }"
                               tree-default-expand-all allow-clear>
                </a-tree-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.状态')" prop="status">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.状态')" style="width: 100%" v-model="queryParam.status" allowClear>
                    <a-select-option v-for="(item, index) in customDict.GoodsStatusEnum" :value="item.id" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.品牌')" prop="brandId">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.品牌')" style="width: 100%" v-model="queryParam.brandId" allowClear>
                    <a-select-option v-for="(item, index) in this.brandList" :value="item.id" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.是否推荐')" prop="isRecommend">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.是否推荐')" style="width: 100%" v-model="queryParam.isRecommend" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.是否新品')" prop="isNew">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.是否新品')" style="width: 100%" v-model="queryParam.isNew" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.是否限购')" prop="isRestriction">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.是否限购')" style="width: 100%" v-model="queryParam.isRestriction" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
                <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                  <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                    <a-icon :type="advanced ? 'up' : 'down'"/>
                  </a>
                </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <!-- 数据展示 -->
      <a-table
        :scroll="{ x: '130%' }"
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, type: 'radio', onChange: onSelectChange}"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>
        <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
          </span>
        <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
         </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-modal>
</template>

<script>
import {activePage} from '@/api/goods/goods'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {listCategory} from "@/api/goods/category";
import Editor from "@/components/Editor/Editor";
import { tableMixin } from '@/store/table-mixin'
import {allBrand} from "@/api/goods/brand";


export default {
  name: 'RadioGoodsSelectForm',
  props: {},
  components: {
    Editor,
    CustomDictTag,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      pageSizeOptions: ['1', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      categoryList: [],
      cateOptions: [],
      brandList: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      open: false,
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        goodsCode: null,
        goodsName: null,
        goodsType: 1,
        categoryId: null,
        orderByGoodsIdList:undefined,
        shopId: null,
        shopCategoryId: null,
        brandId: null,
        isRecommend: undefined,
        isNew: undefined,
        isRestriction: undefined,
        status: 1,
        starName: null,
        prodArea: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('商品.商品编号'),
          dataIndex: 'id',
          width: 100,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 100,
          fixed: 'left',
        },
        /*{
          title: this.$t('商品.包装单位'),
          dataIndex: 'unit',
        },*/
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          scopedSlots: {customRender: 'categoryId'},
        },
        /*   {
             title: this.$t('商品.店铺id'),
             dataIndex: 'shopId',
             align: 'center'
           },
           {
             title: this.$t('商品.商家商品分类表'),
             dataIndex: 'shopCategoryId',
             align: 'center'
           },*/
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          scopedSlots: {customRender: 'brandId'},
        },
        /*{
          title: this.$t('商品.售卖开始时间'),
          dataIndex: 'saleStartTime',
        },
        {
          title: this.$t('商品.售卖结束时间'),
          dataIndex: 'saleEndTime',
        },*/
        {
          title: this.$t('商品.市场价'),
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          dataIndex: 'price',
        },
        {
          title: this.$t('商品.商品状态'),
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('商品.创建时间'),
          dataIndex: 'createTime',
          width: 150,
          scopedSlots: {customRender: 'createTime'},
        }
      ]
    };
  },
  filters: {},
  created() {
    this.getCategoryList();
    this.getBrandList();
  },
  computed: {
    ...mapGetters(['customDict']),
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.resetQuery()
    },
    /**
     *
     * @param starId 明星id ,如果选择了
     * @param records 会先过来的id
     */
    openGoods(starId, records) {
      if (starId) {
        console.log('商品选择,明星id', starId)
        this.queryParam.starId = starId
      }
      this.queryParam.goodsType=1
      if (records) {
        this.selectedRowKeys = records;
        this.queryParam.orderByGoodsIdList = records;
      }
      this.getList()
      this.open = true;
    },
    onOk() {
      //判断有没有选择,拿到列表里选择的
      this.open = false
      this.$emit('goodsSelect', this.selectedRows)
      this.resetQuery()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.resetQuery()
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询明星列表 */
    getList () {
      this.loading = true
      activePage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.selectedRowKeys= [];
      this.selectedRows= [];
      this.queryParam = {
        goodsCode: null,
        goodsName: null,
        goodsType: 1,
        categoryId: null,
        shopId: null,
        shopCategoryId: null,
        orderByGoodsIdList:undefined,
        starId: this.queryParam.starId,
        brandId: null,
        isRecommend: undefined,
        isNew: undefined,
        isRestriction: undefined,
        status: 1,
        starName: null,
        prodArea: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },

    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    getCategoryList() {
      listCategory().then(response => {
        this.categoryList = response.data
        let children = this.handleTree(response.data, 'id', 'pid')
        console.log('children', children)
        this.cateOptions = children
      })
    },
  }
}
</script>
